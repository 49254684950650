.disclamer-button {
  background:transparent;
  color:white;
  border:none;
  outline:none;
  padding:0;
  margin:0;
  font-weight: 700;
  margin-left: auto;

  cursor: pointer;
}