.social-mobile {
  display: none;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.75rem;
    margin-bottom: 0px;
    margin-left: -5px;
  }
  h1 + h1 {
    font-size: 40px;
    line-height: 50px;
    margin-left: -5px;
  }
  h2 {
    font-size: 20px;
  }

  .social {
    display: none;
  }

  header,
  footer {
    padding: 30px;
  }

  footer {
    margin-top: 20px;

    label {
      order: 2;
    }

    .disclaimer-button {
      order: 1;
    }
  }

  .content {
    padding: 0 30px;
  }

  .disclaimer {
    padding: 50px 30px 30px;
  }

  footer {
    display: flex;
    flex-direction: column;

    button:first-child {
      margin-bottom: 80px !important;
    }

    .social-mobile {
      display: block;
      margin: auto;
      margin-bottom: 30px;
    }

    & > button {
      margin: auto !important;
      margin-bottom: 30px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  h1 {
    font-size: 52px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  h1 + h1 {
    font-size: 70px;
    line-height: 70px;
  }
  h2 {
    font-size: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  h1 {
    font-size: 60px;
    line-height: 95px;
    margin-bottom: -20px;
  }
  h1 + h1 {
    font-size: 80px;
    line-height: 160px;
  }
}

@media (min-width: 1280px) {
  h1 {
    font-size: 70px;
    line-height: 95px;
    margin-bottom: 10px;
  }
  h1 + h1 {
    font-size: 100px;
    line-height: 100px;
  }

  .background {
    // & > div {
    //   margin-left: auto;
    //   margin-top: 10%;
    //   width: 50% !important;
    //   height: 90% !important;
    // }
  }
}
