.animation-3 {
  width: 100%;
  height: 100%;

  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  position: absolute;

  canvas {
    clip-path: circle(50%);
    width: 100% !important;
    height: 100% !important;
    z-index: 99;
  }
}
