.investment {
  gap: 0 2rem;
  .investment-svg-container {
    max-width: 100%;
    position: relative;
    display: flex;
    min-height: 440px;
    .investment-svg {
      margin: 0 auto;
      max-width: 100%;
      overflow: visible;
    }
  }
  ul {
    list-style-type: none;
    margin: 3rem 0 0;
    padding: 0;
    li {
      align-items: center;
      display: inline-flex;
    }
  }
}

@keyframes iconmove {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 440px;
  }
}

@keyframes iconrotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes iconrotatereverse {
  to {
    transform: rotate(-360deg);
  }
}
@keyframes iconscale {
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}

.letters {
  padding: 0;
  li {
    list-style-type: none;
    opacity: 0;
    font-size: 1rem;

    &:not(:first-child) {
      margin-right: 6px;
    }
  }

  @for $i from 1 to 12 {
    li:nth-of-type(#{$i}) {
      animation: 3s $i * 0.08s opacityLoader alternate;
    }
  }
  @keyframes opacityLoader {
    to {
      opacity: 1;
    }
  }
}
