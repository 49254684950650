*,
*:before,
*:after {
  box-sizing: border-box;
}

header {
  width: 100%;
  margin: 0;

  display: flex;
  flex-wrap: wrap;

  padding: 80px 100px;
  position: relative;
}

.container-fluid {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  & > * {
    margin-top: auto;
    margin-bottom: auto;

    &:first-child {
      margin-top: 0;
    }
    // &:last-child{margin-bottom:0;}
  }
}

.content {
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  padding: 0 100px;
  position: relative;
}

footer {
  display: flex;
  margin-top: 70px;
  padding: 60px 100px;
  position: relative;
}

nav {
  .nav-content {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;

    .nav-link {
      cursor: pointer;
      margin: 0;

      a {
        color: white;
        font-size: 1rem;
        font-weight: 700;
        margin: 1rem 2rem 1rem 0;
        text-decoration: none;
        text-transform: uppercase;
      }

      &.active a {
        background: linear-gradient(
          271.7deg,
          #ee62b8 0%,
          #ffac6c 48.28%,
          #be73ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

@media (max-width: 650px) {
  nav {
    z-index: 1;
    .nav-content {
      display: block;
      position: absolute;
      width: 100vw;
      height: calc(100vh - 80px);
      margin: -20px 0 0 -30px;
      padding: 0 30px;
      padding-top: 80px;
      background-color: #131313;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      transform: translate(-100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

      .nav-link {
        margin: 1rem 0;
        padding: 10px 0;
        transition-delay: 2s;
      }
    }

    .nav-toggle {
      display: none;
      display: flex;
      flex-direction: column;
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;

      span {
        display: flex;
        width: 29px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: #fff;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 5px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        &:first-child {
          transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
          transform-origin: 50% 0%;
        }
        &:nth-last-child(1) {
          transform-origin: 50% 0%;
        }
      }
    }

    &.open {
      .nav-content {
        transform: translate(0, 0);
      }

      span {
        opacity: 1;
        transform: rotate(45deg) translate(0, -1px);
        background: #eee;

        &:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:nth-last-child(2) {
          transform: rotate(-45deg) translate(-4px, 4px);
        }
      }
    }
  }
}

section {
  color: white;
  font-size: 1.2rem;
  font-weight: lighter;
  line-height: 1.5;
  margin-top: 2rem;
  scroll-snap-type: x proximity;
}

.main-text {
  font-size: 1.6rem;
  font-weight: lighter;
  margin-bottom: 2rem;
}

.grid-cols {
  width: 100%;
  // .col-0 {
  //   h1 {
  //     line-height: 1.5;
  //     margin-bottom: -20px;
  //   }
  //   h1 + h1 {
  //     font-size: 3rem;
  //   }
  // }
  // @media (min-width: 650px) {
  //   .col-0 h1 + h1 {
  //     font-size: 4.2rem;
  //   }
  // }
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 600px repeat(auto-fit, minmax(380px, 1fr));
    gap: 1rem;
    &.methodology {
      grid-template-columns: 800px repeat(auto-fit, minmax(280px, 1fr));
    }
    // .col-0 {
    //   h1 + h1 {
    //     font-size: 90px;
    //   }
    // }
  }
}