.methodology {
  .col-0 {
    @media (min-width: 768px) {
      min-width: 620px;
    }
  }
  .col-1 {
    .col-2 {
      display: flex;

      ul {
        list-style-type: none;
        margin: 0;
        padding-left: 10px;
        li {
          align-items: center;
          padding: 30px 0;
        }
      }
    }
  }
  .benefits-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    li {
      border-left: 4px solid #ffac6c;
      padding-left: 20px;
      &:first-of-type { margin-right: 1rem; }
    }
  }
}
