.carousel {
  margin-top: 2rem;
  .carousel-controls {
    img {
      margin-top: 0.5rem;
      width: 40px;
      height: 36px;
      cursor: pointer;
      @media (max-width: 768px) {
        width: 36px;
        height: 32px;
      }
      &:hover {
        filter: brightness(2);
        transition: all ease 0.5s;
        transform: translateX(2px);
      }

      &:first-child {
        margin-right: 30px;
        &:hover {
          transform: translateX(-2px);
        }
      }
    }

    .not-allowed {
      cursor: not-allowed;
    }
  }
}
