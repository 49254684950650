.flex {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.right {
  flex-direction: row-reverse;
}

.left {
  flex-direction: row;
}

.card-container {
  display: flex;
  width: 100%;
  height: 380px;
  gap: 80px;
  justify-content: space-between;
  align-items: flex-start;

  img {
    clip-path: circle(at center);
    width: 240px;
    height: 240px;
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
      font-size: 48px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: 1.44px;
    }

    .paragraph-container {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      align-items: center;
      height: fit-content;

      p {
        font-size: 18px;
        margin: 0px;
        flex: 1;
        height: 100%;
        height: fit-content;
      }
      .border-box {
        box-sizing: border-box;
        margin: 2px 0px;
        height: 100%;
        width: 8px;
        background: linear-gradient(
          180deg,
          #ee62b8 0%,
          #ffac6c 48.96%,
          #be73ff 100%
        );
      }
    }

    .author {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 700;
    }

    .occupation {
      font-size: 20px;
      text-transform: none;
      font-weight: 400;
    }
  }
}

@media (min-width: 0px) and (max-width: 1250px) {
  .right {
    flex-direction: column-reverse;
  }

  .left {
    flex-direction: column;
  }
  .card-container {
    flex-direction: column;
    height: 100%;
    gap: 32px;
    align-items: center;

    .card-content {
      h3 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.72px;
      }
      .paragraph-container {
        .border-box {
          display: none;
        }
      }

      .author {
        font-size: 12px;
      }

      .occupation {
        font-size: 12px;
      }
    }
  }
}
