.background,
.carousel,
.button,
.disclamer-button,
.social,
.logo {
  animation: opacity 1s 1 linear;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.logo-center {
  animation: fadeout 1s 1 linear;
  animation-delay: 2.2s;
  animation-iteration-count: 1;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}