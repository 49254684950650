.disclaimer {
  background: linear-gradient(133.85deg, #131313 0%, #131313 100%);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 100px;
  .disclaimer-header {
    display:flex;
  }
  .disclaimer-body {
    color: white;
    font-size: 18px;
    padding: 0 0 50px;
    text-align: justify;
  }
}