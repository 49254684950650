.App,
body,
document {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto Condensed", sans-serif;
  box-sizing: border-box;
  background: linear-gradient(133.85deg, #131313 0%, #131313 100%);
}

svg {
  font-family: "Roboto Condensed", sans-serif;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h3 {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 700;
  color: white;
  margin: 0 0 20px 0;
}

h1 {
  font-size: 6.2rem;
  line-height: 1.4;
  font-weight: 700;
  margin: 0;
  color: white;
  text-align: left;
  margin-left: -5px;
  margin-bottom: -30px;

  background: linear-gradient(271.7deg,
      #ee62b8 0%,
      #ffac6c 48.28%,
      #be73ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1+h1 {
  font-size: 10.5rem;
  line-height: 1.2;
  margin-bottom: 0;
  margin-left: -10px;
}

h2 {
  font-size: 1.5rem;
  line-height: 32px;
  color: white;
  font-weight: 300;
}

footer label {
  color: #888;
}

footer {
  flex-wrap: wrap;
}