.social {
  margin: auto 0 auto auto;

  a {
    padding: 5px;

    img {
      width: 27px;
      height: 27px;
    }
  }
}