.who-we-are {
  .crop {
    clip-path: circle(at center);
    max-width: 16rem;
    max-width: 240px;
  }
  p {
    line-height: 1;
    text-align: center;
    a {
      cursor: pointer;
      svg {
        margin-right: 1rem;
        vertical-align: sub;
        fill: #fff;
      }
      &:hover svg {
        fill: #ffac6c;
      }
    }
    &.small-text {
      font-size: 18px;
      line-height: 1.4;
      text-align: left;
    }
  }
  .col-0 h1 + div {
    margin-top: 2rem;
  }
  .col-1,
  .col-2 {
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .col-1 {
    margin-top: 2rem;
  }
  .col-2 img {
    filter: grayscale(0.25);
  }
}
