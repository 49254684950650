.logo {
  img{
    width: auto;
    height: 33px;
  }
  
  &.logo-center {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
